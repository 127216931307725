<template>
  <span
    id="SiteTemplate"
    class="mb-5"
    :class="{ 'grid-base': !isMobile, 'grid-mobile': isMobile }"
  >
    <MinSidebar v-if="isMobile" />
    <MaxSidebar v-if="!isMobile" />

    <!-- <my-component-loading></my-component-loading> -->
    <my-component-certify></my-component-certify>

    <div class="baseAll" :class="{ 'top-space': isMobile }">
      <b-row>
        <GridColum :tamanho="12" :mt="0">
          <slot></slot>
        </GridColum>
      </b-row>
    </div>

    <transition name="fade">
      <div class="modoFoco" v-if="luzApagada"></div>
    </transition>
  </span>
</template>

<script>
// import { EventBus } from "@/main.js";

import GridColum from "@/components/base/GridColum.vue";
import MaxSidebar from "@/components/sidebar/index";
import MinSidebar from "@/components/sidebar/Toggle-sidebar/index";
import MetaService from "@/services/resources/MetaService";
import Cookies from "js-cookie";

const serviceMeta = MetaService.build();

export default {
  name: "SiteTemplate",
  components: {
    GridColum,
    MaxSidebar,
    MinSidebar,
  },
  data() {
    return {
      luzApagada: false,
      client: {
        width: 0,
      },
    };
  },
  mounted() {
    this.getThemeFixed();
    this.getClubVersion();

    document.body.style.backgroundImage = "";

    this.$root.$on("luzOn", (data) => {
      this.luzApagada = true;
    });
    this.$root.$on("luzOff", (data) => {
      this.luzApagada = false;
    });
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  created() {
    if (this.detectOs() == "Android") {
      window.screen.orientation.lock("portrait");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // EventBus.$on("errorNotify", (data) => {
    //   this.$bvToast.toast(data, {
    //     title: "Atenção",
    //     variant: "danger",
    //     autoHideDelay: 5000,
    //     appendToast: true,
    //   });
    // });
  },
  methods: {
    insertTheme() {
      const d = document;
      let theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light");
        if (document.getElementById("slider")) {
          document.getElementById("slider").checked = false;
        }
      } else {
        d.documentElement.setAttribute("data-theme", theme);
        if (theme === "light") {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = false;
          }
        } else {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = true;
          }
        }
      }
      this.$root.$emit("loadTheme");
      this.$root.$emit("loadsettheme");
    },
    getThemeFixed() {
      serviceMeta
        .search("keys[]=theme_fixed_painel")
        .then((resp) => {
          if (resp.theme_fixed_painel === "on") {
            this.getThemeFixedColor();
          } else {
            this.insertTheme();
          }
        })
        .catch((err) => console.error(err));
    },
    getThemeFixedColor() {
      serviceMeta
        .search("keys[]=theme_fixed_painel_color")
        .then((resp) => {
          let theme = "";
          if (resp.theme_fixed_painel_color === "light") {
            Cookies.set("themeClub", "light", { expires: 365 });
            theme = "light";
          } else {
            Cookies.set("themeClub", "dark", { expires: 365 });
            theme = "dark";
          }
          document.documentElement.setAttribute("data-theme", theme);
          this.$root.$emit("loadTheme");
          this.$root.$emit("loadsettheme");
        })
        .catch((err) => console.error(err));
    },
    getClubVersion() {
      serviceMeta
        .search("keys[]=club_version")
        .then((resp) => {
          if (resp.club_version === "v2") {
            Cookies.set("clubVersion", "v2", { expires: 365 });
          } else {
            Cookies.set("clubVersion", "v1", { expires: 365 });
          }
        })
        .catch((err) => console.error(err));
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "../assets/scss/main.scss";

.top-space {
  margin-top: 70px;
}

.grid-mobile {
  display: grid;
  grid-template-columns: 100%;
}

.grid-base {
  display: grid;
  grid-template-columns: 100px calc(100% - 100px);
}

.baseAll {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .cc-tlyw[data-full-view=true] .cc-kxkl .cc-nsge {
    margin-bottom: 80px!important;
  }
}
</style>
