<template>
  <div class="container-logout">
    <ModalPlanos :percentage="valuePercentage"/>
    <div class="logout-btn">
      <router-link to="/minha-conta">
        <img
          v-if="foto_usuario"
          class="foto-usuario"
          :class="{ contains: foto_usuario }"
          :src="foto_usuario"
        />
        <div v-else class="foto-usuario">
          <span>{{ userName.substr(0, 1) }}</span>
        </div>
      </router-link>

      <div class="space-flex">
        <div class="infos-user">
          <p>{{ userName }}</p>
          <span v-if="currentSiteName" @click="openModalPainel">
            {{ currentSiteName }}
          </span>
        </div>

        <a @click="menuConfig">
          <img src="@/assets/icons/config2.svg" class="item-config" />
        </a>

        <ul class="sub-menu" id="sub_config" v-show="sub_menu">
          <li @click="sub_menu = false" v-if="currentThemeFixed == 'off' || !currentThemeFixed">
            <a @click="setTheme">
              <Tema/>
              {{ $t('menu.logout.mudar_tema') }}
            </a>
          </li>
          <li @click="sub_menu = false">
            <a @click="openModalPainel">
              <ViewIcon />
              {{ $t('menu.logout.meus_paineis') }}
            </a>
          </li>
          <li @click="sub_menu = false">
            <a @click="openModalProgress">
              <Cursos/>
              {{ $t('menu.logout.meus_cursos') }}
            </a>
          </li>
          <li @click="sub_menu = false" v-if="permission2">
            <a @click="updateTypeView">
              <ViewWhat/>
              {{ $t('menu.logout.ver_como') }} {{ typeText }}
            </a>
          </li>
          <li @click="sub_menu = false" v-if="permission">
            <router-link to="/configuracoes">
              <CompanyConfig/>
              {{ $t('menu.logout.config') }}
            </router-link>
          </li>
          <li @click="sub_menu = false">
            <a id="logout-btn" @click.prevent="logout">
              <img src="@/assets/icons/sign-out.svg" /> {{ $t('menu.logout.logout') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <ModalPainel/>
    <ModalNovoPainel/>
    <ModalMyCourses
      :allpainels="allPainels"
      :currentSiteId="currentSiteId"
      :courses="Allcourses"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import ModalMyCourses from "@/components/ModalMyCourses.vue";
import ModalPainel from "@/components/ModalPainel.vue";
import ModalNovoPainel from "@/components/ModalNovoPainel.vue";
import ModalPlanos from "@/components/ModalPlanos.vue";

import PlansService from "@/services/resources/PlansService";
import MetaService from "@/services/resources/MetaService";
import MemberMetaService from "@/services/resources/MemberMetaService";

import Tema from "@/components/icons/tema";
import ViewIcon from "@/components/icons/view";
import Cursos from "@/components/icons/cursos";
import ViewWhat from "@/components/icons/view";
import CompanyConfig from "@/components/icons/company-config";
import io from "socket.io-client";
import axios from 'axios'

import MemberService from "@/services/resources/MemberService"

const serviceMember = MemberService.build()
const servicePlans = PlansService.build()
const serviceMeta = MetaService.build()
const serviceMemberMeta = MemberMetaService.build();

const httpGreenn = axios.create({
  baseURL: `${process.env.VUE_GREENN_API_HOST}/api/`,
  timeout: 100000,
})

var socketAdd = ""
if (process.env.VUE_APP_TYPE === "DEV" || Cookies.get("access_level") !== 'student') {
  socketAdd = ""
} else {
  socketAdd = io(process.env.VUE_APP_API_SOCKET_HOST, {
    extraHeaders: {
      Authorization: Cookies.get("auth_greennCourse")
    }
  })
}

export default {
  components: {
    ModalMyCourses,
    ModalPlanos,
    Cursos,
    Tema,
    ViewIcon,
    ViewWhat,
    CompanyConfig,
    ModalPainel,
    ModalNovoPainel
  },
  data() {
    return {
      allPainels: [],
      Allcourses: [],
      typeText: "Aluno",
      permission: false,
      permission2: false,
      name_user: "",
      sub_menu: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
      socket: socketAdd,
      theme: "light",
      themeColor: "Claro",
      existColorFixed: false,
      valuePercentage: 0
    }
  },
  computed: {
    allSites() {
      return this.$store.getters.allSites;
    },
    currentSite() { 
      return this.$store.getters.currentSite;
    },
    currentMember() {
      return this.$store.getters.currentMember;
    },
    currentThemeFixed() {
      if (
        this.$store.getters.metasSite &&
        this.$store.getters.metasSite.theme_fixed_painel
      ) {
        return this.$store.getters.metasSite.theme_fixed_painel
      } else return null
    },
    currentSiteName() {
      if (
        this.$store.getters.currentSite &&
        this.$store.getters.currentSite.currentSite
      ) {
        return this.$store.getters.currentSite.currentSite.name
      } else return null
    },
    currentSiteId() {
      if (
        this.$store.getters.currentSite &&
        this.$store.getters.currentSite.currentSite
      ) {
        return this.$store.getters.currentSite.currentSite.id
      } else return null
    },
    foto_usuario() {
      if (
        this.$store.getters.currentSite &&
        this.$store.getters.currentSite.currentMember
      ) {
        return this.$store.getters.currentSite.currentMember.picture
      } else return null
    },
    userName() {
      if (
        this.$store.getters.currentSite &&
        this.$store.getters.currentSite.currentMember
      ) {
        if (this.$store.getters.currentSite.currentMember.name) {
          const name = this.$store.getters.currentSite.currentMember.name.split(" ")
          if (name.length > 1) {
            return `${name[0]} ${name[1]}`
          } else {
            return name[0];
          }
        } else return ""
      } else return ""
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    fetchCountry() {
      serviceMemberMeta
        .search("keys[]=pais")
        .then((resp) => {
          if (resp.pais !== undefined) {
            if (resp.pais === null) {
              this.fetchCountrySite();
            } else {
              this.selectCountry(resp.pais)
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCountrySite(){
      serviceMeta
        .search("keys[]=pais")
        .then((resp) => {
          if (resp.pais !== undefined) {
            if (resp.pais === null) {
              this.selectCountry('BR')
            } else {
              this.selectCountry(resp.pais)
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectCountry(locale) {
      this.search = "";
      if (locale === "BR") {
        this.$i18n.locale = "pt";
      } else if (
        locale == "AR" ||
        locale == "CO" ||
        locale == "CL" ||
        locale == "EC" ||
        locale == "GT" ||
        locale == "MX" ||
        locale == "PE" ||
        locale == "UY" ||
        locale == "ES"
      ) {
        this.$i18n.locale = "es";
      } else {
        this.$i18n.locale = "en";
      }
    },
    getBitrixChat() {
      serviceMeta
        .search("keys[]=bitrix")
        .then(resp => {
          if (
            resp.bitrix !== null &&
            resp.bitrix !== "off"
          ) this.getBitrixChatId()
        })
        .catch(err => console.log(err))
    },
    getBitrixChatId() {
      serviceMeta
        .search("keys[]=id_bitrix")
        .then(resp => {
          if (resp.id_bitrix !== null) {
            const bitrixScript = document.createElement("script")
            bitrixScript.setAttribute("src", resp.id_bitrix)
            bitrixScript.async = true
            document.head.appendChild(bitrixScript)
          }
        })
        .catch(err => console.log(err))
    },
  async  getJivoChat() {
    await  serviceMeta
        .search("keys[]=jivochat")
        .then((resp) => {
          if (
            resp.jivochat !== null &&
            resp.jivochat !== "off"
          ) this.getJivoChatId()
        })
        .catch(err => console.log(err))
    },
   async getJivoChatId() {
      await serviceMeta
        .search("keys[]=id_jivochat")
        .then(resp => {
          if (resp.id_jivochat !== null) {
            const jivoScript = document.createElement("script")
            jivoScript.setAttribute("src", `//code-eu1.jivosite.com/widget/${resp.id_jivochat}`)
            jivoScript.async = true
            document.head.appendChild(jivoScript)
          }
        })
        .catch(err => console.log(err))
    },
    getCrispChat() {
      serviceMeta
        .search("keys[]=crisp")
        .then(resp => {
          if (
            resp.crisp !== null &&
            resp.crisp !== "off"
          ) this.getCrispChatId()
        })
        .catch(err => console.log(err))
    },
    getCrispChatId() {
      serviceMeta
        .search("keys[]=id_crisp")
        .then(resp => {
          if (resp.id_crisp !== null) {
            const crispScript = document.createElement("script")
            crispScript.setAttribute("src", "https://client.crisp.chat/l.js")
            crispScript.async = true
            document.head.appendChild(crispScript)
            window.$crisp = []
            window.CRISP_WEBSITE_ID = resp.id_crisp
          }
        })
        .catch(err => console.log(err))
    },
    getPorcentage() {
      servicePlans
        .read("me")
        .then(resp => {
          this.valuePercentage = parseInt(resp.percentage, 10)
          if(parseInt(resp.percentage, 10) >= 90 && !Cookies.get('modalPlans')) {
            Cookies.set('modalPlans', true, { expires: 1 })
            this.$root.$emit("bv::show::modal", "modal-plans", "#btnShow")
          }
        })
        .catch(err => console.log(err))
    },
    setCrisp() {

        let notify = document.getElementById("1WZET722PJibpmL_crisp")
      if (notify === null) {
          
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID;
          
        window.$crisp.push([
          "set",
          "user:nickname",
          [`${this.currentSite.currentMember.name}`]
        ])
        window.$crisp.push([
          "set",
          "user:email",
          [`${this.currentSite.currentMember.email}`]
        ])
        window.$crisp.push(["do", "chat:show"])
        window.$crisp.push(["set", "session:segments", [["greenn", "club"], ["club"]]])
        window.$crisp.push(["on", "message:sent", () => {
            const dados_user = this.$store.getters.currentSite.currentMember || localStorage.user;
            httpGreenn.post('/call-evaluation', {
              session_id: window.$crisp.get("session:identifier"),
              user_id: dados_user.id,
              website_id: process.env.CRISP_WEBSITE_ID
            })
        }])
        
        setTimeout(() => {
          let crispScript = document.createElement("script")
          crispScript.setAttribute("src", "https://client.crisp.chat/l.js")
          crispScript.setAttribute("id", "1WZET722PJibpmL_crisp")
          document.getElementsByTagName("head")[0].appendChild(crispScript)
        }, 1000)
      }
    },
    getColor() {
      serviceMeta
        .search("keys[]=main_color")
        .then(resp => {
          this.color = resp.main_color;
          if (this.color !== null) {
            const d = document;
            d.documentElement.setAttribute("data-color", this.color)
            document.documentElement.style.setProperty(
              "--maincolor",
              this.color
            )
            document.documentElement.style.setProperty(
              "--maincolorn",
              this.$func.convertColor(this.color, 210)
            )
            document.documentElement.style.setProperty(
              "--maincolortrans",
              this.color + "0f"
            )
          }
        })
        .catch(err => console.log(err))
    },
    setTheme() {
      if (this.theme === "light") {
        this.theme = "dark"
        Cookies.set("themeClub", "dark", { expires: 365 })
        this.themeColor = "Escuro"
      } else {
        this.theme = "light"
        Cookies.set("themeClub", "light", { expires: 365 })
        this.themeColor = "Claro"
      }
      this.insertTheme()
      setTimeout(() => {
        this.insertTheme()
      }, 500)
    },
    insertTheme() {
      const d = document
      var theme = Cookies.get("themeClub")
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light")
      } else {
        d.documentElement.setAttribute("data-theme", theme)
      }
      this.$root.$emit("loadTheme")
      this.$root.$emit("loadsettheme")
    },
    openModalPainel() {
      if (this.clubVersion === "v2") {
        this.$root.$emit("show-panel-modal-v2");
        return;
      }
      this.$root.$emit("bv::show::modal", "modal-select-painel", "#btnShow")
      this.$root.$emit("getAllPainels")
    },
    getCurrentSiteAndMember() {
      if (process.env.VUE_APP_TYPE !== "DEV" && Cookies.get("access_level") === 'student') {
        this.addInRoom(this.currentMember);
      }
      
      this.allPainels = this.allSites;
      window.document.title = this.currentSite.currentSite.name + " - Greenn.Club";
    },
    getMeRecurses() {
      serviceMember
        .read("/me/all-courses")
        .then(resp => { this.Allcourses = resp })
        .catch(err => console.log(err))
    },
    openModalProgress() {
      this.getMeRecurses()
      this.$root.$emit("bv::show::modal", "modal-my-courses", "#btnShow")
    },
    addInRoom(dados) {
      const data = JSON;
      data.id = dados.current.currentMember.id
      data.room = dados.current.currentSite.id
      data.role = dados.current.currentMember.role
      this.socket.emit("ADD_ROOM", data)
    },
    getPermission() {
      let permission = Cookies.get("access_level")
      let typeView = Cookies.get("type_student")
      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === "1") {
          this.permission = false
          return
        }
        serviceMeta
          .search("keys[]=crisp_owner")
          .then(resp => {
          if(resp.crisp_owner == 'on' && permission == "owner"){
            this.setCrisp();
          } else if(resp.crisp_owner == 'off' || resp.crisp_owner == null){
            this.setCrisp();
          }
        }).catch(err => console.log(err));
        this.getPorcentage()
        this.selectCountry('BR')
        this.permission = true
      } else {
        this.getBitrixChat();
        this.getCrispChat()
        this.fetchCountry();
        this.permission = false;
      }
    },
    getPermission2() {
      let permission2 = Cookies.get("access_level")
      if (permission2 === "admin" || permission2 === "owner") {
        this.permission2 = true
      } else {
        this.permission2 = false
      }
    },
    getTypeView() {
      let typeView = Cookies.get("type_student");
      if (typeView === undefined || typeView === "0") {
        this.typeText = "Aluno"
      } else {
        this.typeText = "Admin"
      }
    },
    updateTypeView() {
      let typeView = Cookies.get("type_student");
      if (typeView === undefined || typeView === "0") {
        Cookies.set("type_student", 1)
      } else {
        Cookies.set("type_student", 0)
      }
      this.$root.$emit("changePermission")
      setTimeout(() => {
        location.reload()
      }, 1000);
    },
    logout() {
      this.$store.dispatch("logoutRequest")
    },
    minha_conta() {
      this.$router.push("/minha-conta")
    },
    menuConfig(e) {
      this.item = e.currentTarget.parentElement.parentElement.parentElement
      this.top = this.item.offsetTop - 200
      const submenu = document.querySelector("#sub_config")
      submenu.style = `top: ${this.top}px`
      this.bottom = this.top + submenu.offsetHeight
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft + 9
      this.sub_menu = !this.sub_menu
    },
    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false
        }
      })
    }
  },
  created(){
    const sessionID = localStorage.getItem("sessionID")
    if (sessionID) {
      this.usernameAlreadySelected = true
      this.socket.auth = { sessionID }
      this.socket.connect()
    }
  },
 async mounted() {
    this.insertTheme()
    setTimeout(() => {
      this.insertTheme()
    }, 500)
    this.eventMouse()
    this.getPermission()
    this.getPermission2()
    this.getTypeView()
    this.getColor()
    await this.getJivoChat();
    this.getCurrentSiteAndMember()
    this.$store.dispatch("getMetasSite")
    this.$store.dispatch("getCertificateConfig")
    this.$store.dispatch("getCurrentSiteAndMember")

    if (process.env.VUE_APP_TYPE !== "DEV" && Cookies.get("access_level") === 'student') {
      this.socket.onAny(() => {})
      this.socket.on("session", ({ sessionID, userID }) => {
        this.socket.auth = { sessionID }
        localStorage.setItem("sessionID", sessionID)
        this.socket.userID = userID
      })
      this.socket.on("LOGOUT", () => {
        this.logoutUser()
      })
      this.socket.on("ADDED_ROOM", () => {})
      this.socket.on("UPDATE_USERS", () => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.container-logout {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
}
.container-logout::before {
  content: "";
  width: 300px;
  height: 1px;
  background: var(--bordercolor);
  display: block;
  margin-left: -40px;
  margin-bottom: 20px;
}

// novo rodape
.logout-btn {
  width: 100%;
  display: flex;
  margin-left: -8px;
}
.foto-usuario {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--maincolor);
  margin-right: 35px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  margin-left: -6px;
}

.foto-usuario span {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.foto-usuario.contains {
  background: none;
}

.space-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infos-user {
  display: block;
  margin-right: 25px;
  width: 102px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.infos-user p {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: var(--fontcolor);
}
.infos-user span {
  font-size: 12px;
  cursor: pointer;
  color: var(--fontcolor);
}
.space-flex img.item-config {
  filter: invert(50%);
  width: 20px;
  cursor: pointer;
}

.sub-menu a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}

.sub-menu a img,
.sub-menu a svg {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 35px;
}
.sub-menu a:hover img,
.sub-menu a:hover svg {
  filter: invert(0);
}

a:hover {
  text-decoration: none !important;
}

img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}

/* Sub menu */
.sub-menu {
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: var(--backgroundcolor);
  padding: 0 50px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 270px;
  bottom: 2px;
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
  border: 1px solid white;
}
.sub-menu li a {
  font-weight: 600;
  color: var(--fontcolor);
  font-size: 14px;
  padding: 10px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: var(--maincolor);
}
.sub-menu li a.router-link-exact-active::before {
  left: -15px;
}

.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img,
.sub-menu li a svg {
  margin-right: 25px;
}
#logout-btn:hover {
  color: #ff0c37;
}

li::marker {
  color: transparent !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 19px;
  margin-left: 70px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #020016;
  transition: 0.3s;
  border: 1px solid currentColor;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: 0.3s;
  box-shadow: 0 0px 15px #2020203d;
  background: #020016 url("../../assets/day1.png");
  background-repeat: no-repeat;
  background-position: center;
}

input:checked + .slider {
  background-color: #020016;
}

input:focus + .slider {
  box-shadow: 0 0 1px #020016;
}

.hide {
  display: none !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
  background: #020016 url("../../assets/night1.png");
  background-repeat: no-repeat;
  background-position: center;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
